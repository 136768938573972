<script>

export default {
  name: 'FineImages',
  components: {
  },
  props: {
    images: {
      type: Array,
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
  },
}
</script>

<template>
  <div v-if="images.length > 0">
    <b-button size="sm">
      <i class="fa fa-image" /> 
      Ver {{images.length}} imagens
    </b-button>
  </div>
  <div v-else>
    <small>--</small>
  </div>

</template>
