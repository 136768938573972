var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modalShowFineImages",
        lazy: "",
        "no-fade": "",
        "no-enforce-focus": "",
        "hide-footer": "",
        size: "lg",
        title: _vm.modal.title,
        "cancel-title": _vm.$t("payments.buttons.close"),
        "body-class": "pt-0 pb-0",
      },
      on: { show: _vm.handleOpen, hidden: _vm.handleClose },
      model: {
        value: _vm.cpHandlerModal,
        callback: function ($$v) {
          _vm.cpHandlerModal = $$v
        },
        expression: "cpHandlerModal",
      },
    },
    [
      _c("b-container", [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _vm._v(" AIT "),
                _c("br"),
                _c("strong", [_vm._v(" " + _vm._s(_vm.modal_data.ait) + " ")]),
              ]),
              _c("div", { staticClass: "col-8" }, [
                _vm._v(" Descrição "),
                _c("br"),
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.modal_data.description) + " "),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [_vm._v(" ")]),
              _vm.modal_data.car
                ? _c("div", { staticClass: "col-4" }, [
                    _vm._v(" Placa "),
                    _c("br"),
                    _c("strong", [
                      _vm._v(
                        " " + _vm._s(_vm.modal_data.car.license_plate) + " "
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "col-8" }, [
                _vm._v(" Local "),
                _c("br"),
                _c("strong", [
                  _vm._v(" " + _vm._s(_vm.modal_data.issue_location) + " "),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "div",
              { staticClass: "row" },
              _vm._l(_vm.modal_data.images, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-3" },
                  [
                    _c("img-uploaded", {
                      attrs: {
                        label: _vm.$t(
                          "fleetFines.tickets_types." + item.image_type
                        ),
                        image: item.image_url,
                        download: true,
                      },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }