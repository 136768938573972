<script>
import Layout from '@layouts/main';
import ContentControll from '@components/content-controll';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';
import { formatPrice } from '@utils/numbers';
import FineImages from '@components/fines/images';
import ModalFineImages from '@components/modals/fines/fine-images';

import CAR_FINES from '@graphql/fine/queries/car.gql';

export default {
  name: 'FleetFines',
  page: {
    title: 'Frota',
  },
  components: {
    ModalFineImages,
    FineImages,
    Layout,
    FleetHeader,
    ContentControll,
    ContentLoading,
  },
  data: () => {
    return {
      fineOpened: {},
      modalShowFineImages: false,
      fineModal: {},

      fields: [
        {
          key: 'description',
          label: 'Infração',
          sortable: true,
        },
        {
          key: 'driver',
          label: 'Motorista',
        },
        {
          key: 'images',
          label: 'Imagens',
        },
        {
          key: 'issued_at',
          label: 'Data infração',
          sortable: true,
        },
        {
          key: 'amount',
          label: 'Valor',
        },
      ],
    };
  },
  apollo: {
    fines: {
      query: CAR_FINES,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      update: data => data.finesByCar,
    },
  },
  methods: {
    openFineImagesModal( thisItem ) {
      this.modalShowFineImages = true;
      this.fineOpened = thisItem;
    },

    getAmountColor(type) {
      return type === 'CREDIT' ? '#4dbd74' : '#f86c6b';
    },
    setFormatPrice(value) {
      return formatPrice('R$', value);
    },
    goTo(item) {
      const userLink = this.userLink(item.id);
      this.$router.push({
        path: userLink,
      });
    },
    checkIfNew(_issued) {
      const time_now = this.$moment();
      return time_now.diff(this.$moment(_issued), 'days') <= 14;
    },
  },
};
</script>

<template>
  <Layout>
    <fleet-header :id="$route.params.id" />
    <div v-if="!$apollo.queries.fines.loading" class="animated fadeIn">
      <b-card body-class="p-0">
        <b-card-header>
          <h4 class="mt-2">
            Histórico de Multas
          </h4>
        </b-card-header>
        <b-card-body class="p-0">
          <b-table responsive striped hover
                   :items="fines.items" :fields="fields"
          >
            <template v-slot:cell(description)="data">
              <b-badge v-if="checkIfNew(data.item.issued_at)" class="mr-1" variant="success">
                Novo
              </b-badge>
              <strong>{{ data.item.ait }}</strong>
              : {{ data.item.description }}
              <br>
              <span class="small text-muted">
                Autuador: {{ data.item.issue_institution ? data.item.issue_institution : 'Não identificado' }}
                <br>
                Local: {{ data.item.issue_location }}
              </span>
            </template>
            <template v-slot:cell(driver)="data">
              <span v-if="data.item.driver">
                <b-link :to="'/drivers/profile/' + data.item.driver.id">{{ data.item.driver.name }}</b-link>
              </span>
              <span v-else>Não identificado</span>
            </template>
            <template
              v-slot:cell(issued_at)="data"
            >
              {{ data.item.issued_at | moment('DD/MM/YYYY @ HH:mm') }}
            </template>
            <template v-slot:cell(amount)="data">
              <span
                :style="'color:' + getAmountColor(data.item.type)"
              >{{ setFormatPrice(data.item.amount) }}</span>
            </template>
            <template v-slot:cell(images)="data">
              <div @click="openFineImagesModal(data.item)">
                <fine-images :images="data.item.images"/>
              </div>
            </template>
          </b-table>
        </b-card-body>
        <b-card-body v-if="fines.errorType || fines.items.length === 0" class="p-0">
          <content-controll :service="fines" />
        </b-card-body>
      </b-card>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>

    <modal-fine-images
      v-model="modalShowFineImages"
      :modal_data="fineOpened"
    />  

  </Layout>
</template>

<style scoped>
</style>
