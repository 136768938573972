var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.images.length > 0
    ? _c(
        "div",
        [
          _c("b-button", { attrs: { size: "sm" } }, [
            _c("i", { staticClass: "fa fa-image" }),
            _vm._v(" Ver " + _vm._s(_vm.images.length) + " imagens "),
          ]),
        ],
        1
      )
    : _c("div", [_c("small", [_vm._v("--")])])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }