var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "img-uploaded" },
    [
      _vm.label
        ? _c("p", { staticClass: "label caption" }, [
            _vm._v(" " + _vm._s(_vm.label) + " "),
          ])
        : _vm._e(),
      _c("div", { staticClass: "wrapper-image preview" }, [
        _c("img", {
          staticClass: "img-center",
          attrs: { src: _vm.image || _vm.imageUrl, download: "" },
        }),
        _c("a", {
          ref: "downloadInput",
          staticStyle: { display: "none" },
          attrs: {
            href: _vm.image || _vm.imageUrl,
            target: "_blank",
            download: "",
          },
        }),
      ]),
      _vm.download
        ? _c(
            "b-button",
            {
              staticClass: "bg-default mt-2",
              attrs: { size: "sm" },
              on: {
                click: function ($event) {
                  return _vm.downloadImage()
                },
              },
            },
            [_c("i", { staticClass: "fa fa-download" }), _vm._v(" Download ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }