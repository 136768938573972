<template>
  <section class="img-uploaded">
    
    <p 
      class="label caption"
      v-if="label">
      {{ label }}
    </p>

    <div class="wrapper-image preview">
      <img
        :src="image || imageUrl"
        class="img-center"
        download />
        <a ref="downloadInput" :href="image || imageUrl" target="_blank" download style="display: none"/>
    </div>

    
    <b-button
      v-if="download"
      class="bg-default mt-2"
       size="sm"
      @click="downloadImage()">
        <i class="fa fa-download"></i> Download
    </b-button>
  </section>
</template>

<script>

export default {
  name: 'img-uploaded',

  props: {
    label: {
      default: 'Arquivo',
    },

    image: {
      default: null
    },
    download: {
      default: false,
    }
  },

  data() {
    return {
    }
  },
  methods: {
    downloadImage() {
      this.$refs.downloadInput.click();
    }
  }
};
</script>

<style lang="scss" scoped>
.img-uploaded {
  input {
    display:none;
  }


  .wrapper-image {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content:center;
    text-align:center;
    cursor:pointer;
    border: 3px dashed #b3b3b3;
    max-height: 125px;
    height:125px;

    &.preview {
      padding:0;
      border:0;
      cursor: default;
      
      img {
        max-height: 125px;
        height:125px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
      }
    }

    &.progress-content {
      border:0;

      .progress {
        background: transparent;
      }
    }
  }

  button {
    display: block;
    margin: 0 auto;
  }

  .label {
    color: #979797;
    margin:10px 0;
    text-align: center;
    &.caption {
      font-size:12px;
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}
</style>
