var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      !_vm.$apollo.queries.fines.loading
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c(
                "b-card",
                { attrs: { "body-class": "p-0" } },
                [
                  _c("b-card-header", [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(" Histórico de Multas "),
                    ]),
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: "p-0" },
                    [
                      _c("b-table", {
                        attrs: {
                          responsive: "",
                          striped: "",
                          hover: "",
                          items: _vm.fines.items,
                          fields: _vm.fields,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(description)",
                              fn: function (data) {
                                return [
                                  _vm.checkIfNew(data.item.issued_at)
                                    ? _c(
                                        "b-badge",
                                        {
                                          staticClass: "mr-1",
                                          attrs: { variant: "success" },
                                        },
                                        [_vm._v(" Novo ")]
                                      )
                                    : _vm._e(),
                                  _c("strong", [_vm._v(_vm._s(data.item.ait))]),
                                  _vm._v(
                                    " : " + _vm._s(data.item.description) + " "
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    { staticClass: "small text-muted" },
                                    [
                                      _vm._v(
                                        " Autuador: " +
                                          _vm._s(
                                            data.item.issue_institution
                                              ? data.item.issue_institution
                                              : "Não identificado"
                                          ) +
                                          " "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        " Local: " +
                                          _vm._s(data.item.issue_location) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(driver)",
                              fn: function (data) {
                                return [
                                  data.item.driver
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/drivers/profile/" +
                                                  data.item.driver.id,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(data.item.driver.name)
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c("span", [_vm._v("Não identificado")]),
                                ]
                              },
                            },
                            {
                              key: "cell(issued_at)",
                              fn: function (data) {
                                return [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          data.item.issued_at,
                                          "DD/MM/YYYY @ HH:mm"
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(amount)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "span",
                                    {
                                      style:
                                        "color:" +
                                        _vm.getAmountColor(data.item.type),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setFormatPrice(data.item.amount)
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                            {
                              key: "cell(images)",
                              fn: function (data) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openFineImagesModal(
                                            data.item
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("fine-images", {
                                        attrs: { images: data.item.images },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1283703524
                        ),
                      }),
                    ],
                    1
                  ),
                  _vm.fines.errorType || _vm.fines.items.length === 0
                    ? _c(
                        "b-card-body",
                        { staticClass: "p-0" },
                        [
                          _c("content-controll", {
                            attrs: { service: _vm.fines },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "animated fadeIn" },
            [_c("content-loading")],
            1
          ),
      _c("modal-fine-images", {
        attrs: { modal_data: _vm.fineOpened },
        model: {
          value: _vm.modalShowFineImages,
          callback: function ($$v) {
            _vm.modalShowFineImages = $$v
          },
          expression: "modalShowFineImages",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }