
<script>
import ImgUploaded from '@components/shared/img-uploaded';

export default {
  name: 'ModalFineImages',
  components: {
    ImgUploaded
  },
  model: {
    prop: 'handlerModal',
    event: 'change',
  },
  props: {
    modal_data: {
      type: Object,
      required: true,
    },
    handlerModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      images: [],
      modal: {
        title: this.$t('drivers.labels.fineImages'),
      },
    };
  },
  computed: {
    cpHandlerModal: {
      get() {
        return this.handlerModal;
      },
      set(value) {
        this.modalController = value;
        this.$emit('change', Boolean(value));
      },
    },
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit('closeModal', true);
    },
    showModal() {
      this.block_ui = false;
    },

    handleOpen() {
      this.window_closed = false;
    },
    handleClose() {
      this.window_closed = true;
    },

  },
};
</script>

<template>
  <b-modal
    id="modalShowFineImages"
    lazy
    no-fade
    no-enforce-focus
    hide-footer
    size="lg"
    :title="modal.title"
    :cancel-title="$t('payments.buttons.close')"
    v-model="cpHandlerModal"
    @show="handleOpen"
    @hidden="handleClose"
    body-class="pt-0 pb-0"
  >
    <b-container>
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-4">
              AIT
              <br />
              <strong>
                {{ modal_data.ait }}
              </strong>
            </div>

            <div class="col-8">
              Descrição <br>
              <strong>
                {{ modal_data.description }}
              </strong>
            </div>
            <div class="col-12">&nbsp;</div>
            <div
              v-if="modal_data.car"
              class="col-4"
            >
              Placa <br>
              <strong>
                {{ modal_data.car.license_plate }}
              </strong>
            </div>

            <div class="col-8">
              Local <br>
              <strong>
                {{ modal_data.issue_location }}
              </strong>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-body">
          <div class="row">
            <div v-for="(item, index) in modal_data.images" :key="index" class="col-3">
              <img-uploaded
                :label="$t('fleetFines.tickets_types.' + item.image_type)"
                :image="item.image_url"
                :download="true"
              />
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </b-modal>
</template>

<style scoped>
.lines {
  padding-bottom: 30px;
}
.titles {
  margin-bottom: 8px;
  font-size: 14px;
  color: #000000;
}
.texts {
  margin-bottom: 0;
  font-size: 14px;
  color: #000000;
}
.kind {
  color: #25aad6;
}
.comments {
  background-color: #eaedf3 !important;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 76px;
}
</style>
